<template>
  <div>
    <div id="chart"></div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
export default {
  data () {
    return {
      loading: false
    }
  },
  props: ['money', 'months', 'id', 'currency'],
  mounted () {
    this.initTimelineChart()
  },
  created () {
    this.loading = true
  },
  methods: {
    ...mapMutations(['SET_LOADING_BUTTON', 'SET_LOADING_DATA']),
    ...mapActions('wallet', ['getGraphic']),
    initTimelineChart () {
      const options = {
        "chart": {
            "height": 350,
            "type": "pie",
            "toolbar": {
                "show": false
            }
        },
        "series": [102057043, 58318310, 37906901, 29159155, 29159155, 14579577, 14579577, 5831831],
        "labels": ["Private | 102,057,043", "Seed | 58,318,310", "Marketing | 37,906,901", "Public | 29,159,155", "Team | 29,159,155", "Advisory | 14,579,577", "Treasury | 14,579,577", "Contingency | 5,831,831"]
      }
      var chart = new ApexCharts(document.querySelector('#chart'),
          options
      );
      chart.render();
    }
  },
  computed: {
    ...mapState(['abilities', 'role', 'userAuth']),
    ...mapState('wallet', ['chart'])
  }
}
</script>
<style>
    .apexcharts-legend-text{
        color: white !important;
    }
    .apexcharts-legend-series{
        margin-top: 10px !important;
    }
</style>