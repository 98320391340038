<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <b-card class="bg-blur border-card">
                    <Distribution />
                </b-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-3">
                <Stage />
            </div>
            <div class="col-12">
                <Timeline />
            </div>
        </div>
    </div>
</template>
<script>
import Distribution from './Distribution.vue'
import Stage from './StageCard.vue'
import Timeline from './Timeline.vue'
export default {
    components: {
        Stage,
        Timeline,
        Distribution
    }
}
</script>