<template>
  <div>
    <div class="mt-5">
      <div class="row">
        <div class="col-md-12">
          <div style="display: inline-block; width: 100%; overflow-y: auto">
            <ul class="timeline timeline-horizontal">
              <li class="timeline-item">
                <div class="timeline-badge primary">
                  <i class="glyphicon glyphicon-check"></i>
                </div>
                <b-card class="timeline-panel bg-full">
                    <h3>
                        Q1
                    </h3>
                    <b-card class="bg-blur">
                        <ul>
                            <li>
                                Token Creation
                            </li>
                            <li>
                                Creation white book
                            </li>
                            <li>
                                Distribution
                            </li>
                            <li>
                                Creation of Upacademy
                            </li>
                        </ul>
                    </b-card>
                </b-card>
              </li>
              <li class="timeline-item">
                <div class="timeline-badge success">
                  <i class="glyphicon glyphicon-check"></i>
                </div>
                <b-card class="timeline-panel bg-full">
                    <h3>
                        Q2
                    </h3>
                    <b-card class="bg-blur">
                        <ul>
                            <li>
                                Venta privada
                            </li>
                            <li>
                                Exchange 
                            </li>
                        </ul>
                    </b-card>
                </b-card>
              </li>
              <li class="timeline-item">
                <div class="timeline-badge info">
                  <i class="glyphicon glyphicon-check"></i>
                </div>
                <b-card class="timeline-panel bg-full">
                    <h3>
                        Q3
                    </h3>
                    <b-card class="bg-blur">
                        <ul>
                            <li>
                                Coinmarketcap
                            </li>
                            <li>
                                Price trackers
                            </li>
                        </ul>
                    </b-card>
                </b-card>
              </li>
              <li class="timeline-item">
                <div class="timeline-badge danger">
                  <i class="glyphicon glyphicon-check"></i>
                </div>
                <b-card class="timeline-panel bg-full">
                    <h3>
                        Q4
                    </h3>
                    <b-card class="bg-blur">
                        <ul>
                            <li>Wallet móvil</li>
                        </ul>
                    </b-card>
                </b-card>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import url(https://fonts.googleapis.com/css?family=Cinzel:700);
/* Timeline */
.timeline-horizontal{
    padding-top: 50px !important;
}
.timeline,
.timeline-horizontal {
  list-style: none;
  padding: 20px;
  position: relative;
}
.timeline:before {
  top: 40px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eee;
  left: 50%;
  margin-left: -1.5px;
}
.timeline .timeline-item {
  margin-bottom: 20px;
  position: relative;
}
.timeline .timeline-item:before,
.timeline .timeline-item:after {
  content: "";
  display: table;
}
.timeline .timeline-item:after {
  clear: both;
}
.timeline .timeline-item .timeline-badge {
  color: #fff;
  width: 54px;
  height: 54px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: 18px;
  left: 50%;
  margin-left: -25px;
  background-color: #bbdefb;
  border: 3px solid #fff;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.timeline .timeline-item .timeline-badge i,
.timeline .timeline-item .timeline-badge .fa,
.timeline .timeline-item .timeline-badge .glyphicon {
  top: 2px;
  left: 0px;
}
.timeline .timeline-item .timeline-badge.primary {
  background-color: #E3C87A;
}
.timeline .timeline-item .timeline-badge.info {
  background-color: #E3C87A;
}
.timeline .timeline-item .timeline-badge.success {
  background-color: #E3C87A;
}
.timeline .timeline-item .timeline-badge.warning {
  background-color: #E3C87A;
}
.timeline .timeline-item .timeline-badge.danger {
  background-color: #E3C87A;
}
.timeline .timeline-item .timeline-panel {
  position: relative;
  width: 46%;
  float: left;
  right: 16px;
}
.timeline .timeline-item .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -16px;
  display: inline-block;
  border-top: 16px solid transparent;
  border-left: 16px solid #c0c0c0;
  border-right: 0 solid #c0c0c0;
  border-bottom: 16px solid transparent;
  content: " ";
}
.timeline .timeline-item .timeline-panel .timeline-title {
  margin-top: 0;
  font-size: 25px;
  font-family: "Waiting for the Sunrise", cursive;
  color: #0c0c0c;
}
.timeline .timeline-item .timeline-panel .timeline-body > p,
.timeline .timeline-item .timeline-panel .timeline-body > ul {
  margin-bottom: 0;
  font-family: "Cinzel", sans-serif;
  color: #a79898;
}
.timeline .timeline-item .timeline-panel .timeline-body > p + p {
  margin-top: 0px;
}
.timeline .timeline-item:last-child:nth-child(even) {
  float: right;
}
.timeline .timeline-item:nth-child(even) .timeline-panel {
  float: right;
  left: 16px;
}
.timeline .timeline-item:nth-child(even) .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-horizontal {
  list-style: none;
  position: relative;
  padding: 20px 0px 20px 0px;
  display: inline-block;
}
.timeline-horizontal:before {
  height: 3px;
  top: auto;
  bottom: 26px;
  left: 56px;
  right: 0;
  width: 100%;
  margin-bottom: 20px;
}
.timeline-horizontal .timeline-item {
  display: table-cell;
  height: 280px;
  width: 20%;
  min-width: 320px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.timeline-horizontal .timeline-item .timeline-panel {
  top: auto;
  bottom: 64px;
  display: inline-block;
  float: none !important;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
  margin-bottom: 20px;
}
.timeline-horizontal .timeline-item .timeline-panel:before {
  top: auto;
  bottom: -16px;
  left: 28px !important;
  right: auto;
  border-right: 16px solid transparent !important;
  border-top: 16px solid rgba(19, 18, 16, 0.6) !important;
  border-bottom: 0 solid rgba(19, 18, 16, 0.6) !important;
  border-left: 16px solid transparent !important;
}
.timeline-horizontal .timeline-item:before,
.timeline-horizontal .timeline-item:after {
  display: none;
}
.timeline-horizontal .timeline-item .timeline-badge {
  top: auto;
  bottom: 0px;
  left: 43px;
}
</style>
