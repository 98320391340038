<template>
  <div>
    <b-card class="bg-blur border-card" v-if="active">
      <h4>Token sale ends in</h4>
      <Countdown
        class="mt-4 mb-4"
        labelColor="#ffffff"
        :deadline="formatDate(active.end)"
        mainColor="#ffffff"
      />
      <div class="mt-4">
        <span>
          ${{ active.sold ? active.sold : 0 }} sold out of {{ active.maximum }}
        </span>
        <b-progress
          :value="calcPer(active.sold, active.maximum)"
          max="100"
        ></b-progress>
      </div>
      <b-button
        variant="primary"
        size="lg"
        class="mt-4 w-100"
        @click="
            $router.push({
                name: 'Purchase-Method',
                params: { id: 'token', type: 'token' }
            })
        ">
        Buy token
      </b-button>
    </b-card>
    <b-modal
      class="text-white"
      centered
      hide-footer
      title="Buy tokens"
      v-model="modalPurchase"
    >
      <Purchase :data="active" @close="modalPurchase = false" />
    </b-modal>
  </div>
</template>
<script>
import { Countdown } from "vue3-flip-countdown";
import { mapActions, mapState } from "vuex";
import Purchase from "./Purchase.vue";
import moment from "moment";
export default {
  components: {
    Purchase,
    Countdown,
  },
  data() {
    return {
      loading: false,
      modalPurchase: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions("stage", ["getStageActive"]),
    getData() {
      this.loading = true;
      this.getStageActive().then(() => {
        this.loading = false;
      });
    },
    calcPer(sold, max) {
      let total = (sold * 100) / max;
      return total;
    },
    formatDate(date) {
      return moment(date).format("Y-MM-DD HH:mm");
    },
  },
  computed: {
    ...mapState("stage", ["active"]),
  },
};
</script>
<style scoped>
.item {
  text-align: center;
}
.item .bg-secondary {
  text-align: center;
}
</style>
