<template>
    <div>
        <div class="price">
            <h2>
                1USD = {{ data.price }} B7T
            </h2>
        </div>
        <div class="pros">
            <div>
                Send to the wallet the amount equivalent to B7T that you want to receive
            </div>
        </div>
        <div class="mt-4">
            <h3>
                Payment Methods
            </h3>
            <div class="row">
                <div class="col-6 col-sm-4 col-lg-4 mb-3" @click="form.payment_method = 'usdtt'">
                    <b-card class="h-100" :class="form.payment_method == 'usdtt' ? 'pay-active' : ''">
                        <div class="text-center">
                            <img src="@/assets/icons/usdt.png" class="w-70 click" alt="">
                            <h4 class="mt-3">
                                USDT
                            </h4>
                        </div>
                    </b-card>
                </div>
                <div class="col-6 col-sm-4 col-lg-4 mb-3" @click="form.payment_method = 'bta'">
                    <b-card class="h-100" :class="form.payment_method == 'bta' ? 'pay-active' : ''">
                        <div class="text-center">
                            <img  src="@/assets/icons/bta.png" class="w-70 click" alt="">
                            <h4 class="mt-3">
                                B7T
                            </h4>
                        </div>
                    </b-card>
                </div>
                <!-- <div class="col-6 col-sm-4 col-lg-4 mb-3" @click="form.payment_method = 'btc'">
                    <b-card class="h-100" :class="form.payment_method == 'btc' ? 'pay-active' : ''">
                        <div class="text-center">
                            <img  src="@/assets/icons/btc.png" class="w-70 click" alt="">
                            <h4 class="mt-3">
                                BTC
                            </h4>
                        </div>
                    </b-card>
                </div> -->
            </div>
        </div>
        <div class="text-right mt-4">
            <b-button variant="primary-outline" size="lg" class="mr-3" @click="$emit('close')">
                Cancel
            </b-button>
            <ButtonAction lg="true" :disabled="form.payment_method == null" :loading="loading" loadingTx="Creating..." title="Confirm purchase" @click="onSubmit"/>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    props: ['data'],
    data () {
        return {
            form: {
                title: null,
                price: null,
                payment_method: null,
                model: 'Token',
                model_id: null
            },
            loading: false
        }
    },
    created () {
        if(this.data) {
            this.form.title = 'Purchase tokens'
            this.form.price = 0
            this.form.model_id = 1
        }
    },
    methods: {
        ...mapActions('cart', ['storeCart']),
        onSubmit () {
            this.loading = true
            this.storeCart(this.form).then(response => {
                this.loading = false
                openNotification()
                this.$emit('close')
                this.$router.push({ name: 'Cart', params: { id: response.id } })
            })
        }
    }
}
</script>
<style scoped>
    .pay-active{
        border: 5px solid #E3C87A !important;
        border-radius: 20px !important;
    }
    .pay-active h4{
        color: #E3C87A !important;
    }
</style>